import {
  mdiPlus, mdiDeleteOutline, mdiCheck, mdiPencilOutline, mdiInformationOutline,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { i18n } from '@/plugins/i18n'
import drugSet from '@/api/drugSet/drugSet'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'

export default function useDrugList() {
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'id',
      width: 70,
    },
    {
      text: i18n.t('code'),
      value: 'drugset_code',
      width: 120,
    },
    {
      text: i18n.t('name'),
      value: 'drugset_name',
    },
    {
      text: i18n.t('price'),
      value: 'drugset_price',
      align: 'end',
    }, {
      text: i18n.t('status'),
      value: 'drugset_active_id',
      align: 'center',
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      align: 'center',
    },
  ])
  const dataTableList = ref([])
  const options = ref({})
  const totalDataTableList = ref(0)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const loading = ref(false)
  const totalPage = ref(0)
  const segmentId = ref(1)
  const searchtext = ref('')
  const statusList = ref(
    [{
      'value': '',
      'th': 'สถานะทั้งหมด',
      'en': 'All status',
      'la': 'ສະຖານະພາບທັງໝົດ',
    },
      {
        'value': 1,
        'th': 'ปกติ',
        'en': 'Normal',
        'la': 'ທຳມະດາ',
      },
      {
        'value': '0',
        'th': 'ระงับ',
        'en': 'Suspend',
        'la': 'ໂຈະ',
      }],
  )
  const drug_status_id = ref(1)
  const isAddNewDrugSet = ref(false)
  const isEditDrugSet = ref(false)
  const isUpdateStatus = ref(false)
  const dataEdit = ref({})

  onMounted(() => {
    fetchDataTable()
  })

  const fetchDataTable = () => {
    loading.value = true
    drugSet.getDrugSetList({
      searchtext: searchtext.value,
      drugset_active_id: drug_status_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    })
      .then(res => {
        const { data, count, segment, count_of_page } = res
        totalDataTableList.value = count
        totalPage.value = count_of_page
        dataTableList.value = data
        segmentId.value = segment
        loading.value = false
      })
      .catch(err => {
        console.log('get Drug list error : ', err)
        loading.value = false
      })

  }

  watch([searchtext, drug_status_id], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    columns,
    dataTableList,
    options,
    searchtext,
    totalDataTableList,
    footer,
    statusList,
    loading,
    drug_status_id,
    isAddNewDrugSet,
    totalPage,
    isEditDrugSet,
    dataEdit,
    isUpdateStatus,
    segmentId,
    fetchDataTable,
    icons: {
      mdiPlus,
      mdiDeleteOutline, mdiCheck, mdiPencilOutline, mdiInformationOutline,

    },
  }
}
